
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as dashboardhwoFzO38TEMeta } from "/home/forge/productions.smake.cloud/releases/v1.0.0/pages/[slug]/dashboard.vue?macro=true";
import { default as entranceNSVhvrQdU3Meta } from "/home/forge/productions.smake.cloud/releases/v1.0.0/pages/[slug]/entrance.vue?macro=true";
import { default as indexzcEhvHL7hCMeta } from "/home/forge/productions.smake.cloud/releases/v1.0.0/pages/[slug]/index.vue?macro=true";
import { default as inventoryXHrfiGpPAsMeta } from "/home/forge/productions.smake.cloud/releases/v1.0.0/pages/[slug]/inventory.vue?macro=true";
import { default as loginQkdTbZgDiKMeta } from "/home/forge/productions.smake.cloud/releases/v1.0.0/pages/[slug]/login.vue?macro=true";
export default [
  {
    name: "slug-dashboard",
    path: "/:slug()/dashboard",
    component: () => import("/home/forge/productions.smake.cloud/releases/v1.0.0/pages/[slug]/dashboard.vue")
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entranceNSVhvrQdU3Meta || {},
    component: () => import("/home/forge/productions.smake.cloud/releases/v1.0.0/pages/[slug]/entrance.vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/productions.smake.cloud/releases/v1.0.0/pages/[slug]/index.vue")
  },
  {
    name: "slug-inventory",
    path: "/:slug()/inventory",
    meta: inventoryXHrfiGpPAsMeta || {},
    component: () => import("/home/forge/productions.smake.cloud/releases/v1.0.0/pages/[slug]/inventory.vue")
  },
  {
    name: "slug-login",
    path: "/:slug()/login",
    meta: loginQkdTbZgDiKMeta || {},
    component: () => import("/home/forge/productions.smake.cloud/releases/v1.0.0/pages/[slug]/login.vue")
  }
]