import axios from "axios";

export const useLocalSettingsStore = defineStore("localSettings", () => {
    const config = useRuntimeConfig();

    const settings = useSlugLocalStorage(
        "production-administration-localSettings-settings",
        {
            locale: config.public.locale,
            lastSeen: Date.now().toString(),
        },
    );

    watchEffect(() => {
        axios.defaults.headers.common["Accept-Language"] =
            settings.value.locale;
    });

    return toRefs(settings.value);
});
