import {
    type IconDefinition,
    faBell,
    faCopyright,
    faShuffle,
    faUser,
} from "@fortawesome/pro-duotone-svg-icons";

export const duotoneIcons: IconDefinition[] = [
    faBell,
    faCopyright,
    faShuffle,
    faUser,
];
