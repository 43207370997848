import {
    type IconDefinition,
    faAngleUp,
    faCaretDown,
    faCheck,
    faCircle,
    faCircleSmall,
    faCircleStar,
    faCircleUser,
    faEllipsisVertical,
    faImages,
    faLockKeyhole,
    faLockKeyholeOpen,
    faShoppingBasket,
    faText,
    faUser,
    faUserSecret,
} from "@fortawesome/pro-solid-svg-icons";

export const solidIcons: IconDefinition[] = [
    faAngleUp,
    faCaretDown,
    faCheck,
    faCircle,
    faCircleSmall,
    faCircleStar,
    faCircleUser,
    faEllipsisVertical,
    faImages,
    faLockKeyhole,
    faLockKeyholeOpen,
    faShoppingBasket,
    faText,
    faUser,
    faUserSecret,
];
