import {
    type IconDefinition,
    faAngleDown,
    faAngleUp,
    faAnglesUpDown,
    faArrowRightFromBracket,
    faAsterisk,
    faBarsFilter,
    faCalculator,
    faCartXmark,
    faCheck,
    faChevronDown,
    faCircle,
    faCircleCheck,
    faCircleStar,
    faCopy,
    faFile,
    faFileImport,
    faHexagonImage,
    faPalette,
    faPencil,
    faPersonDigging,
    faPersonToDoor,
    faSave,
    faShoppingBasket,
    faText,
    faTimes,
    faUser,
    faUserSecret,
    faUsers,
    faX,
} from "@fortawesome/pro-light-svg-icons";
import { faEllipsis } from "@fortawesome/pro-light-svg-icons/faEllipsis";

export const lightIcons: IconDefinition[] = [
    faAngleDown,
    faAngleUp,
    faAnglesUpDown,
    faArrowRightFromBracket,
    faAsterisk,
    faBarsFilter,
    faCalculator,
    faCartXmark,
    faCheck,
    faChevronDown,
    faCircle,
    faCircleCheck,
    faCircleStar,
    faCopy,
    faEllipsis,
    faFile,
    faFileImport,
    faHexagonImage,
    faPalette,
    faPencil,
    faPersonDigging,
    faPersonToDoor,
    faSave,
    faShoppingBasket,
    faText,
    faTimes,
    faUser,
    faUserSecret,
    faUsers,
    faX,
];
