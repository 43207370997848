import axios from "axios";
import type {
    LoginRequest,
    ProductionLoginData,
    RefreshTokenData,
} from "~/lib/api/types/auth";

const fetchRefreshTokenRetryLimit = 3;
let currentFetchRefreshTokenRetries = 0;

export async function refresh(
    token: string | undefined,
): Promise<RefreshTokenData> {
    try {
        const result = (
            await axios.post<RefreshTokenData>("auth/refresh", {
                refresh_token: token,
            })
        ).data;
        currentFetchRefreshTokenRetries = 0;

        return result;
    } catch (error) {
        if (currentFetchRefreshTokenRetries < fetchRefreshTokenRetryLimit) {
            currentFetchRefreshTokenRetries += 1;

            return refresh(token);
        }

        throw error;
    }
}

export async function login(
    loginRequest: LoginRequest,
): Promise<ProductionLoginData> {
    return (await axios.post<ProductionLoginData>("/auth/login", loginRequest))
        .data;
}
